import React from 'react';
import styled from 'styled-components';
import { colors, icons } from 'herald-fe-shared';

import { TierUsageLabelWithLogo } from '~/components/pricing/Limits';

const Styled = styled.div`
  margin-bottom: 2rem;
  padding: 1rem 0;
  border-radius: 8px;

  .all-plans {
    display: grid;
    grid-template-columns: repeat(3, 33%);
    column-gap: 2rem;
  }
  .all-plans__feature {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
  }
  .all-plans__feature p {
    margin: 0;
    text-align: left;
  }
  .all-plans__feature__icon {
    border-radius: 50%;
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
    border: 1px solid ${colors.GREEN()};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
  }
  .all-plans__feature__icon svg {
    fill: ${colors.GREEN()};
  }

  @media screen and (max-width: 1024px) {
    .all-plans {
      grid-template-columns: repeat(2, 50%);
    }
  }
  @media screen and (max-width: 600px) {
    order: 1;
    margin: 0;
    margin-top: 2rem;
    .all-plans {
      grid-template-columns: 100%;
    }
  }
  @media screen and (max-width: 400px) {
    .all-plans {
      max-width: 260px;
      margin: auto;
    }
  }
`;

const AllPlansFeature: React.FC<any> = (props) => {
  const { title } = props;
  return (
    <div className="all-plans__features">
      <div className="all-plans__feature">
        <div className="all-plans__feature__icon">
          <icons.CheckDouble />
        </div>
        {typeof title === 'string' ? <p>{title}</p> : title}
      </div>
    </div>
  );
};

const AllPlans: React.FC = () => {
  return (
    <Styled>
      <h5 className="tier__name text-caps">Included in all plans</h5>
      <div className="all-plans">
        <AllPlansFeature title="Weekly Voice of the Customer Reports" />
        <AllPlansFeature
          title={
            <TierUsageLabelWithLogo
              label="Issue Tracker Integrations"
              logo={['jira', 'github', 'gitlab', 'clubhouse', 'linear']}
            />
          }
        />
        <AllPlansFeature title="Data Export" />
        <AllPlansFeature
          title={
            <TierUsageLabelWithLogo
              label="Browser Extensions"
              logo={['chrome', 'firefox']}
            />
          }
        />
        <AllPlansFeature
          title={
            <TierUsageLabelWithLogo
              label="Front Plugin"
              logo="front"
              logoStyle={{ width: '2.5rem', height: '1rem' }}
            />
          }
        />
        <AllPlansFeature
          title={<TierUsageLabelWithLogo label="Slack App" logo="slack" />}
        />
        <AllPlansFeature title="API Access" />
        <AllPlansFeature
          title={
            <TierUsageLabelWithLogo
              label="Intercom Integration"
              logo="intercom"
            />
          }
        />
        <AllPlansFeature title="GDPR & CCPA Compliance" />
      </div>
    </Styled>
  );
};

export default AllPlans;
