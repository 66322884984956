import * as React from 'react';

const SvgBicycle = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 543 462" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M76.208 32.153c8.806-37.98 62.854-29.692 91.273-29.693 22.791 0 170.41-9.84 185.947 9.841 23.37 29.6-63.555 16.766-74.586 36-34.92 60.885 216.481-2.876 172.998 52.222-21.646 27.43-88.57-.453-88.57 35.57 0 36.168 42.553 14.4 48.057 36.168 11.5 45.482 97.071 2.702 89.72 49.037-5.698 35.908-93.86 6.933-112.397 34.444-12.49 18.536-23.154 25.81-14.762 46.526 15.825 39.068 114.035 18.707 136.887 54.126 21.988 34.08 40.556 103.851 0 103.851H14.312c-6.472 0 0-16.575 0-16.575 5.177-47.836 105.923-14.816 105.923-62.932 0-30.728-56.086-20.887-101.925-20.887-30.645 0-16.244-29.492-3.998-57.583 25.111-57.6 186.79 23.853 157.72-31.855-17.62-33.763-90.988-6.149-87.018-44.026 3.79-36.166 74.544 1.01 87.017-33.149 16.383-44.868-130.9 3.328-121.09-43.419 13.208-62.932 123.885 3.63 121.09-49.296-2.425-45.905-106.205-23.588-95.823-68.37z"
        fillOpacity={0.49}
        fill="#E6ECFA"
        fillRule="nonzero"
      />
      <path
        d="M487.178 412.97c14.901 8.242-6.209 35.931-18.626 48.744h-22.59c-16.05-7.33-45.654-23.223-35.667-28.137 12.484-6.143-6.935-13.078-12.483-28.732-5.548-15.654 16.446-15.257 32.298-15.257s3.37-20.608 20.013-25.364c16.645-4.755 10.9 13.673 10.9 25.364 0 11.69 7.53 13.078 26.155 23.381z"
        fill="#8280FF"
        fillRule="nonzero"
      />
      <path
        d="M444.915 391.74c4.203 7.005 7.096 45.955 12.91 69.205h-6.54c.991-3.17-19.22-13.474-25.76-17.635-6.539-4.162.793-11.295 3.37-12.484 2.575-1.189-21.6-12.286-17.835-26.552 2.793-10.584 17.41 12.49 21.401 1.981 2.532-6.667 9.481-19.47 12.454-14.516z"
        fillOpacity={0.13}
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        d="M459.41 461.342c-1.219-3.367-2.723-7.952-4.26-13.276m-2.082-71.134c-4.557 6.54-.395 9.88-2.97 21.768-2.896 13.366-.745 27.287 2.97 41.737m0 0c-8.454-11.393-19.22-21.895-36.658-32.595m36.658 32.595a268.589 268.589 0 002.082 7.63m0 0c4.788-6.804 16.307-21.243 24.074-24.57"
        stroke="#FBFBFB"
        strokeWidth={0.891}
        strokeLinecap="round"
      />
      <path
        d="M434.978 403.58c-17.837 9.867 7.432 43.011 22.296 58.35h27.04c19.212-8.777 54.648-27.8 42.694-33.682-14.943-7.353 8.302-15.655 14.943-34.393s-19.687-18.264-38.662-18.264c-18.975 0-4.032-24.668-23.956-30.36-19.925-5.693-13.046 16.366-13.046 30.36 0 13.995-9.013 15.655-31.309 27.99z"
        fill="#DEC8FA"
        fillRule="nonzero"
      />
      <path
        d="M485.567 378.168c-5.03 8.386-8.495 55.011-15.452 82.842h7.827c-1.186-3.795 23.008-16.129 30.835-21.11 7.827-4.98-12.397-5.726-12.397-12.098 0-9.927 34.218-17.55 29.712-34.629-3.343-12.669-20.84 14.95-25.617 2.372-3.03-7.982-11.35-23.306-14.908-17.377z"
        fillOpacity={0.13}
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        d="M468.217 461.484c1.458-4.03 3.258-9.519 5.098-15.892m2.492-85.15c5.455 7.828.472 11.827 3.556 26.058 3.466 16 .891 32.663-3.556 49.96m0 0c10.12-13.638 23.007-26.209 43.88-39.017m-43.88 39.017c-.82 3.19-1.66 6.251-2.492 9.132m0 0c-5.732-8.143-19.52-25.427-28.817-29.41"
        stroke="#FBFBFB"
        strokeWidth={1.067}
        strokeLinecap="round"
      />
      <path
        d="M53.652 431.194c8.402 4.647-3.5 20.26-10.502 27.484H30.414c-9.05-4.134-25.74-13.094-20.11-15.865 7.038-3.464-3.91-7.374-7.039-16.2-3.128-8.826 9.273-8.603 18.211-8.603 8.938 0 1.9-11.619 11.284-14.3 9.385-2.68 6.145 7.71 6.145 14.3 0 6.592 4.245 7.374 14.747 13.184z"
        fill="#8280FF"
        fillRule="nonzero"
      />
      <path
        d="M29.824 419.223c2.37 3.95 4.001 25.912 7.28 39.021h-3.688c.558-1.788-10.837-7.598-14.524-9.944-3.687-2.346.447-6.368 1.9-7.038 1.451-.67-12.179-6.927-10.056-14.97 1.575-5.968 9.816 7.04 12.066 1.116 1.428-3.759 5.346-10.978 7.022-8.185z"
        fillOpacity={0.13}
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        d="M37.996 458.468c-.687-1.899-1.535-4.484-2.402-7.486m-1.173-40.107c-2.57 3.686-.223 5.57-1.675 12.273-1.633 7.536-.42 15.386 1.675 23.533m0 0c-4.767-6.424-10.838-12.345-20.669-18.378m20.67 18.378c.385 1.502.78 2.944 1.172 4.301m0 0c2.7-3.836 9.194-11.976 13.574-13.853"
        stroke="#FBFBFB"
        strokeWidth={0.503}
        strokeLinecap="round"
      />
      <path
        d="M24.22 425.9c-10.057 5.563 4.19 24.25 12.571 32.898h15.246c10.832-4.948 30.812-15.674 24.072-18.99-8.425-4.146 4.681-8.827 8.425-19.392 3.745-10.565-11.1-10.297-21.798-10.297-10.699 0-2.274-13.909-13.508-17.118-11.233-3.21-7.355 9.227-7.355 17.118 0 7.89-5.082 8.826-17.653 15.78z"
        fill="#DEC8FA"
        fillRule="nonzero"
      />
      <path
        d="M52.743 411.571c-2.837 4.73-4.789 31.017-8.712 46.71h4.413c-.669-2.14 12.972-9.095 17.386-11.904 4.413-2.808-6.99-3.228-6.99-6.82 0-5.598 19.293-9.897 16.752-19.526-1.885-7.143-11.75 8.43-14.443 1.338-1.709-4.5-6.4-13.14-8.406-9.798z"
        fillOpacity={0.13}
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        d="M42.961 458.548c.822-2.272 1.837-5.367 2.875-8.96m1.404-48.01c3.076 4.413.267 6.668 2.005 14.692 1.955 9.021.503 18.417-2.005 28.17m0 0c5.706-7.69 12.973-14.779 24.741-22m-24.74 22a181.574 181.574 0 01-1.405 5.148m0 0c-3.232-4.592-11.006-14.337-16.249-16.583"
        stroke="#FBFBFB"
        strokeWidth={0.602}
        strokeLinecap="round"
      />
      <path
        d="M215.374 395.732c0 34.71-28.138 62.847-62.847 62.847S89.68 430.441 89.68 395.732s28.138-62.847 62.847-62.847c34.71 0 62.847 28.138 62.847 62.847zm-119.409 0c0 31.24 25.324 56.562 56.562 56.562 31.238 0 56.562-25.323 56.562-56.562 0-31.238-25.324-56.562-56.562-56.562-31.238 0-56.562 25.324-56.562 56.562zM447.18 395.731c0 34.71-28.138 62.847-62.848 62.847-34.709 0-62.847-28.138-62.847-62.847s28.138-62.847 62.847-62.847c34.71 0 62.847 28.138 62.847 62.847zm-119.41 0c0 31.24 25.324 56.562 56.562 56.562 31.24 0 56.562-25.323 56.562-56.562 0-31.238-25.323-56.562-56.562-56.562-31.238 0-56.562 25.324-56.562 56.562z"
        fill="#09205B"
        fillRule="nonzero"
      />
      <path
        d="M415.45 382.524a.53.53 0 01.723.215 35.745 35.745 0 01.351 32.706 35.76 35.76 0 01-26.405 19.302.53.53 0 01-.606-.45.542.542 0 01.455-.612 34.685 34.685 0 0014.847-6.105 34.675 34.675 0 0010.418-44.326.542.542 0 01.216-.73z"
        fillOpacity={0.27}
        fill="#09205B"
        fillRule="nonzero"
      />
      <path
        d="M412.267 386.146a.462.462 0 01.621.221 31.193 31.193 0 01-14.418 40.988.462.462 0 01-.623-.216.474.474 0 01.218-.628 30.248 30.248 0 0015.041-17.037 30.263 30.263 0 00-1.062-22.703.473.473 0 01.223-.625zM117.312 382.524a.53.53 0 00-.724.215 35.745 35.745 0 00-.35 32.706 35.747 35.747 0 0026.405 19.302.53.53 0 00.605-.45.542.542 0 00-.455-.612 34.681 34.681 0 01-25.596-18.72 34.676 34.676 0 01.332-31.71.543.543 0 00-.217-.73z"
        fillOpacity={0.27}
        fill="#09205B"
        fillRule="nonzero"
      />
      <path
        d="M120.495 386.146a.463.463 0 00-.622.221 31.192 31.192 0 00-1.1 23.417 31.2 31.2 0 0015.52 17.571.462.462 0 00.622-.216.475.475 0 00-.218-.628 30.265 30.265 0 01-15.04-17.037 30.263 30.263 0 011.06-22.703.472.472 0 00-.222-.625z"
        fillOpacity={0.27}
        fill="#09205B"
        fillRule="nonzero"
      />
      <circle
        fill="#292929"
        fillRule="nonzero"
        cx={234.729}
        cy={395.504}
        r={19.355}
      />
      <path
        d="M235.868 376.376l-89.033 4.1s-11.693 2.96-11.385 15.938c.307 12.98 13.662 15.712 13.662 15.712l90.855 2.505s15.711-4.554 13.662-18.217c-2.175-14.498-17.76-20.038-17.76-20.038z"
        stroke="#000"
        strokeWidth={1.366}
      />
      <path
        fill="#7E7E7E"
        d="M272.11 406.467h-3.108v9.842h-34.185v2.59h37.293v-2.59z"
      />
      <path
        d="M269.483 171.554l4.417-7.946c5.762 4.148 17.13 3.25 22.517-22.7-2.117-12.649-31.812-17.442-29.714 12.181l-9.63 13.041c.627 2.983 6.545 9.843 12.41 5.424zm94.355 79.186c-8.927 0-42.353-5.75-42.353-5.75l7.742-12.296s18.43 9.05 31.196 8.938c2.198-.019 3.893-.007 5.548.005 1.855.014 3.66.027 6.065-.005 3.965-.053 9.108.911 9.108.911l2.277 4.098s1.207 10.883-.91 8.37c-.27-.32-.512-.992-.81-1.817-.71-1.965-1.737-4.808-4.2-5.87-2.368-1.02-4.733-1.498-6.83 0-3.189 2.278-5.239 3.416-6.833 3.416zm-88.104 127.5l-4.403 16.79h-10.563l3.791-50.475h19.81l-8.635 33.684zm-26.432 16.41l-3.87 30h-12.98v-63.928l21.86 2.05-5.01 31.878z"
        fill="#C44949"
      />
      <path
        d="M259.093 405.264V395.03c.001-2.426 3.716-.04 6.142 0 2.713.043 7.65-.993 7.65-.993s4.797 4.265 17.093 6.474c1.27.23 2.331 1.68 3.022 2.92.498.893-.124 1.922-1.143 2.006-7.404.614-32.764 2.521-32.764-.172z"
        fill="#A0EEF8"
        fillRule="nonzero"
      />
      <path
        d="M289.378 350.248c5.291-16.84 6.797-24.637 4.782-33.245l-4.782-8.027h-40.076l14.118 14.175-1.822 27.097h27.78z"
        fill="#130D59"
        fillRule="nonzero"
      />
      <rect
        fill="#0D0D0D"
        fillRule="nonzero"
        x={161.635}
        y={278.975}
        width={57.837}
        height={19.583}
        rx={9.791}
      />
      <path
        d="M372.036 294.857l-33.473 12.524h-.877l-.034-.17-107.705-.456v11.385l109.755-.455-.012-.057h.24l32.106-13.89.91 19.582 4.47 66.617a4.99 4.99 0 109.956-.684l-4.634-65.933-1.858-28.463-1.813-39.116h-6.733l-.298 39.116zM159.814 378.88l24.501-80.778h-9.472l-25.048 80.778-4.202 16.634a4.858 4.858 0 109.422 2.38l4.8-19.014z"
        fill="#40E2F8"
      />
      <path
        d="M178.485 298.103l-24.592 78.33-3.735 21.943a2.568 2.568 0 11-5.021-1.059l4.658-18.436 25.047-80.778h3.643z"
        fillOpacity={0.15}
        fill="#0F69D2"
        fillRule="nonzero"
      />
      <path
        fillOpacity={0.13}
        fill="#2B5DDC"
        fillRule="nonzero"
        d="M246.57 314.042h95.408l-2.049 3.416-91.766.455z"
      />
      <path
        fillOpacity={0.09}
        fill="#1118AC"
        fillRule="nonzero"
        d="M379.322 378.71l-2.277 2.505-5.01-77.192-32.106 13.435 2.05-3.644 33.244-14.345z"
      />
      <path
        d="M208.77 328.331c-36.615-36.798-33.32-71.12-25.275-84.479 17.67 8.926 43.72 11.84 53.966 11.84 12.57 7.105 39.85 37.116 51.917 51.234h-42.58l-17.534-21.404s23.632 33.752 29.146 59.943c2.505 11.898-2.733 30.058-2.733 30.058h-26.413v-26.927l-20.494-20.265z"
        fill="#130D59"
        fillRule="nonzero"
      />
      <path
        fillOpacity={0.13}
        fill="#000"
        fillRule="nonzero"
        d="M231.768 288.311l15.03 18.444h12.522z"
      />
      <path
        d="M228.81 285.351s.226.338.64.97c33.498 50.897 32.112 64.986 26.228 88.518h-4.098s4.356-18.545 2.96-31.195c-2.386-21.619-21.35-51.587-25.09-57.323l-.64-.97z"
        fillOpacity={0.23}
        fill="#000"
        fillRule="nonzero"
      />
      <path
        fillOpacity={0.22}
        fill="#000"
        fillRule="nonzero"
        d="M237.233 424.707l-4.554-.683v-48.502h3.643zM264.331 394.65l-3.416-.683 3.416-43.719h3.643z"
      />
      <path
        d="M237.462 256.204c-22.315 8.425-51.234-5.294-54.422-12.125 45.177-53.739 66.49-76.434 75.598-80.153 21.86 0 26.946 31.423 27.553 43.492l48.73 24.364c1.743 7.717-3.872 12.297-9.792 20.551-41.716-11.659-62.316-26.319-64.669-33.758l-22.998 37.63z"
        fill="#DEC8FA"
        fillRule="nonzero"
      />
      <path
        d="M223.116 258.71c1.64.91 9.867-1.215 14.117-2.505l22.771-37.344-9.108 2.96-1.594 2.732-26.186 34.156z"
        fillOpacity={0.12}
        fill="#FFF"
        fillRule="nonzero"
      />
      <circle
        fill="#000"
        fillRule="nonzero"
        cx={152.527}
        cy={395.732}
        r={16.395}
      />
      <path
        d="M270.348 437.48l-39.49 1.288s-1.915-1.493-1.82-4.782c.085-3.038 1.82-10.474 1.82-10.474s4.546 1.091 7.514 1.138c3.32.054 8.425-1.138 8.425-1.138s4.742 6.09 20.91 8.594c1.47.228 2.867 1.841 3.817 3.228.64.931-.047 2.11-1.176 2.146z"
        fill="#ADEFF8"
        fillRule="nonzero"
      />
      <rect
        fill="#000"
        fillRule="nonzero"
        x={225.848}
        y={437.459}
        width={40.987}
        height={4.099}
        rx={2.049}
      />
      <ellipse
        fill="#0D0D0D"
        fillRule="nonzero"
        cx={375.57}
        cy={252.339}
        rx={6.603}
        ry={5.92}
      />
      <path
        d="M169.035 317.378l-26.416-10.1m22.432 22.248l-53.056-21.404"
        stroke="#000"
        strokeWidth={1.822}
      />
      <path
        fill="#C4C4C4"
        fillRule="nonzero"
        d="M85.126 303.567h88.35l-2.049 5.465h-86.3z"
      />
      <path
        d="M205.355 223.187c-5.917 5.64-15.306 1.261-20.266-5.237-5.476-7.174 2.277-22.998 2.277-22.998 7.211-12.752 31.651-39.62 64.67-20.038 0 0 11.161-6.117 17.987-3.87 4.056 1.333 8.881 6.374 8.881 6.374l1.594 3.871s-5.885-5.008-10.475-6.375c-8.823-2.629-22.998 5.18-22.998 5.18l-23.615 24.421s16.274 10.041 25.892 15.03c4.62 2.395 10.93 0 10.93 0l-2.277 3.642s-6.855 2.78-10.93 0c-8.954-6.107-27.78-14.364-27.78-14.364s-10.068 10.721-13.89 14.364z"
        fill="#6989FA"
        fillRule="nonzero"
      />
      <path
        d="M244.976 169.45c-6.74 14.936-35.75 42.352-61.026 45.312-2.55-7.104 3.492-20.72 7.06-26.186 20.644-12.6 60.706-34.065 53.966-19.127z"
        fillOpacity={0.11}
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        d="M234.273 172.41c-6.74 14.937-25.73 35.294-51.006 38.254 0-6.376 4.402-17.533 7.97-22.998 20.645-12.6 49.776-30.194 43.036-15.256z"
        fillOpacity={0.12}
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        d="M276.627 147.988c2.498-7.643 11.587 2.96 19.9-5.289 6.202-7.099-.533-8.71-8.313-14.962-7.78-6.25-23.619 1.544-28.628 4.618-5.008 3.074-12.893-8.206-22.76-10.722-9.868-2.516-4.339 6.544-11.153 6.068-6.815-.477-13.687-6.664-14.4 3.558-.716 10.222-15.47 5.766-27.7 7.65 0 0-14.943-.452-17.269 6.346-2.056 6.006 6.957 8.458 6.717 14.801-.24 6.343-8.575-.74-11.386 16.167-2.81 16.907 27.732 17.647 29.536 20.511 6.442 10.23 15.55-11.63 18.172 1.271 1.714 8.435 17.188-3.338 22.765-8.908 12.526-12.514 36.777-17.428 44.52-41.109z"
        fill="#15135D"
        fillRule="nonzero"
      />
      <path
        d="M211.271 131.26c-.715 10.221-15.469 5.766-27.698 7.65 0 0-14.943-.452-17.27 6.346-2.055 6.005 6.958 8.458 6.717 14.801-.24 6.343-8.574-.74-11.385 16.167 17.634 6.926 61.912 11.396 97.951-26.13 7.03-7.662 24.26-19.868 36.94-7.395 6.203-7.098-.532-8.71-8.313-14.96-7.78-6.252-23.619 1.543-28.627 4.616-5.008 3.074-12.894-8.205-22.76-10.722-9.869-2.516-4.339 6.545-11.154 6.068-6.814-.476-13.686-6.663-14.4 3.56z"
        fillOpacity={0.05}
        fill="#000"
        fillRule="nonzero"
      />
      <ellipse
        fill="#5EE5ED"
        fillRule="nonzero"
        cx={277.765}
        cy={146.679}
        rx={5.01}
        ry={5.237}
      />
      <ellipse
        fillOpacity={0.33}
        fill="#FFF"
        fillRule="nonzero"
        cx={277.765}
        cy={146.617}
        rx={3.643}
        ry={3.809}
      />
      <path
        d="M288.24 125.73l-9.336 15.712-2.05 3.643s-.39 1.223.228 1.594c1.14.683 1.822-.228 1.822-.228l2.277-3.415 11.157-14.574-4.098-2.732z"
        fill="#19D6E2"
        fillRule="nonzero"
      />
      <path
        d="M288.241 125.73l-9.336 15.712-2.05 3.643s-.492 1.14.228 1.14c.456 0 .911-.685.911-.685l2.05-3.87 9.563-15.029-1.366-.91z"
        fill="#05C5D2"
        fillRule="nonzero"
      />
      <path
        d="M233.59 374.84v-33.246s-18.569-15.475-28.69-27.097c-11.244-12.91-25.048-35.977-25.048-35.977 2.96 24.592 29.83 53.51 49.867 69.45v26.87h3.871z"
        fillOpacity={0.19}
        fill="#000"
        fillRule="nonzero"
      />
      <rect
        fill="#000"
        fillRule="nonzero"
        x={256.816}
        y={404.669}
        width={30.968}
        height={3.643}
        rx={1.822}
      />
      <path
        fillOpacity={0.17}
        fill="#000"
        fillRule="nonzero"
        d="M267.518 350.02h-5.692l1.821-26.869-5.237-5.01h9.108l3.188 5.01z"
      />
      <path
        d="M286.418 211.802c-5.804-1.612-1.594-15.484-1.594-15.484l.911 5.92.456 5.237 38.254 19.129s-7.753.081-9.564-2.506c-1.594-2.277-24.365-11.158-28.463-12.296z"
        fillOpacity={0.12}
        fill="#FFF"
        fillRule="nonzero"
      />
      <path
        fillOpacity={0.15}
        fill="#000"
        fillRule="nonzero"
        d="M358.145 249.374l-25.73-6.83-2.733 3.87 16.395 2.504 8.197 1.14 6.604.682z"
      />
      <path
        fill="#E4BE9A"
        fillRule="nonzero"
        d="M93.779 239.354h65.124v64.213H93.779z"
      />
      <path
        d="M101.521 303.112v-63.53m-7.514 23.226h64.668"
        stroke="#000"
        strokeWidth={1.366}
      />
      <path
        fillOpacity={0.19}
        fill="#FFF"
        fillRule="nonzero"
        d="M132.944 288.993h22.771v11.385h-22.771z"
      />
      <path
        fill="#E0AC7C"
        fillRule="nonzero"
        d="M101.976 218.406h21.404v21.105h-21.404z"
      />
      <path
        fillOpacity={0.19}
        fill="#FFF"
        fillRule="nonzero"
        d="M107.44 224.325h9.146v4.573h-9.146z"
      />
      <circle
        fill="#000"
        fillRule="nonzero"
        cx={384.56}
        cy={399.432}
        r={12.524}
      />
      <rect
        fill="#000"
        fillRule="nonzero"
        x={4.842}
        y={458.263}
        width={534.533}
        height={3.626}
        rx={1.813}
      />
    </g>
  </svg>
);

export default SvgBicycle;
