import React from 'react';
import { colors, icons, utils, Plan } from 'herald-fe-shared';

import Image from '~/components/Image';

import { IAugmentedPlan } from '~/pages/pricing';
import { ITierLimit } from '~/components/pricing/TierUsageSection';

const PLANS_TO_SHOW = utils.constants.PLANS_METADATA;

export const getTiers = (plans: Plan[]) => {
  const plansWithInfo = plans
    .map((original) => {
      const metadata = PLANS_TO_SHOW[original.type];
      if (metadata) {
        return {
          ...original,
          ...metadata,
          price: (
            <span>
              ${original.cents / 100}
              <span className="text-small text-gray">/month</span>
            </span>
          ),
        };
      }
      return null;
    })
    .filter((p) => p)
    .sort((a, b) => a.cents - b.cents);

  // plansWithInfo.push({
  //   id: 'contact',
  //   displayName: 'Contact',
  //   price: <span style={{ fontSize: '1.8rem' }}>Contact Us</span>,
  //   limits:{
  //     quotes: 'Unlimited' as any,
  //     personas: 'Unlimited' as any,
  //     editors: 'Unlimited' as any,
  //   },
  //   features: { zapier: true, customDomain: true, b2b: true },
  // } as any);
  return plansWithInfo;
};

interface ITierUsageLabelWithLogoProps {
  label: string;
  logo: string | string[];
  logoStyle?: React.CSSProperties;
}

export const TierUsageLabelWithLogo: React.FC<ITierUsageLabelWithLogoProps> = (
  props
) => {
  const { label, logo, logoStyle } = props;
  return (
    <div className="tier__labels__label--with-logos">
      <p>{label}</p>
      <div
        className={`tier__labels__label__logos tier__labels__label__logos--${
          typeof logo === 'string' ? 'single' : 'multiple'
        }`}
      >
        {typeof logo === 'string' ? (
          <div className="tier__labels__label__logos__logo" style={logoStyle}>
            <Image src={`logos/${logo}.png`} />
          </div>
        ) : (
          logo.map((l) => (
            <div
              key={l}
              className="tier__labels__label__logos__logo"
              style={logoStyle}
            >
              <Image src={`logos/${l}.png`} />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

const Enabled = <icons.CheckDouble style={{ fill: colors.GREEN() }} />;

type TierLimitSection = ITierLimit[];

export const getProductUsage = (plans: IAugmentedPlan[]) => {
  return [
    {
      name: (
        <div>
          <p className="text-no-margin">Contributors</p>
          <p
            className="small text-no-margin"
            style={{ color: 'gray', fontSize: '.75rem', lineHeight: '1rem' }}
          >
            Additional seats
          </p>
        </div>
      ),
      limits: plans.map((p) => (
        <p className="text-no-margin">
          {utils.strings.formatNumber(p.limits?.editors)}{' '}
          {p.extraCents?.editors && (
            <>
              <br />
              <span style={{ color: 'gray', fontSize: '.8rem' }}>
                {p.extraCents?.editors >= 0 ? (
                  <>
                    ${p.extraCents?.editors / 100}/month
                    {p.maxEditors >= 0 ? <> (Up to {p.maxEditors})</> : ''}
                  </>
                ) : (
                  'None'
                )}
              </span>
            </>
          )}
        </p>
      )),
    },
    {
      name: (
        <div>
          <p className="text-no-margin">Viewers</p>
          <p
            className="small text-no-margin"
            style={{ color: 'gray', fontSize: '.75rem', lineHeight: '1rem' }}
          >
            Other team members
          </p>
        </div>
      ),
      limits: plans.map(() => <p className="text-no-margin">No Limit</p>),
    },
    {
      name: (
        <div>
          <p className="text-no-margin">Feedback Tracked</p>
          {/*<p
            className="small text-no-margin"
            style={{ color: 'gray', fontSize: '.75rem', lineHeight: '1rem' }}
          >
            Archived feedback not counted
          </p>*/}
        </div>
      ),
      limits: plans.map((p) => (
        <p className="text-no-margin">
          {p.limits?.quotes > 100
            ? 'No Limit'
            : utils.strings.formatNumber(p.limits?.quotes)}
        </p>
      )),
    },
    {
      name: (
        <div>
          <p className="text-no-margin">Personas</p>
          <p
            className="small text-no-margin"
            style={{ color: 'gray', fontSize: '.75rem', lineHeight: '1rem' }}
          >
            Analyze feedback by segment
          </p>
        </div>
      ),
      limits: plans.map((p) =>
        p.limits?.personas ? (
          utils.strings.formatNumber(p.limits?.personas)
        ) : (
          <p className="text-no-margin">None</p>
        )
      ),
    },
    {
      name: (
        <div>
          <p className="text-no-margin">Companies</p>
          <span className="small" style={{ color: 'gray', fontSize: '.75rem' }}>
            See feedback by company
          </span>
        </div>
      ),
      limits: plans.map((p) => (p.features.b2b ? Enabled : null)),
    },
    {
      name: (
        <div>
          <p className="text-no-margin">Smart Suggestions</p>
          <span className="small" style={{ color: 'gray', fontSize: '.75rem' }}>
            AI to categorize feedback
          </span>
        </div>
      ),
      limits: plans.map((p) => (p.features.topicSuggestion ? Enabled : null)),
    },
    {
      name: (
        <div>
          <p className="text-no-margin">Custom Domain</p>
          <p
            className="small text-no-margin"
            style={{ color: 'gray', fontSize: '.75rem', lineHeight: '1rem' }}
          >
            For your Public Portal
          </p>
        </div>
      ),
      limits: plans.map((p) => (p.features.customDomain ? Enabled : null)),
    },
    // {
    //   name: 'Voice of the Customer Report',
    //   limits: [Enabled, Enabled, Enabled, Enabled],
    // },
    // {
    //   name: (
    //     <TierUsageLabelWithLogo
    //       label="Issue Tracker Integrations"
    //       logo={['jira', 'github', 'gitlab', 'clubhouse', 'linear']}
    //     />
    //   ),
    //   limits: [Enabled, Enabled, Enabled, Enabled],
    // },
    // {
    //   name: 'API Access',
    //   limits: [Enabled, Enabled, Enabled, Enabled],
    // },
    // {
    //   name: 'Data Export',
    //   limits: [Enabled, Enabled, Enabled, Enabled],
    // },
    {
      name: 'Single Sign On',
      limits: [null, null, null, 'Available'],
    },
  ];
};

export const CHANNEL_INTEGRATIONS: TierLimitSection = [
  {
    name: (
      <TierUsageLabelWithLogo
        label="Browser Extensions"
        logo={['chrome', 'firefox']}
      />
    ),
    limits: [Enabled, Enabled, Enabled, Enabled],
  },
  {
    name: (
      <TierUsageLabelWithLogo
        label="Front Plugin"
        logo="front"
        logoStyle={{ width: '2.5rem', height: '1rem' }}
      />
    ),
    limits: [Enabled, Enabled, Enabled, Enabled],
  },
  {
    name: <TierUsageLabelWithLogo label="Slack App" logo="slack" />,
    limits: [Enabled, Enabled, Enabled, Enabled],
  },
];

export const getIntegrations = (plans: IAugmentedPlan[]) => [
  // {
  //   name: <TierUsageLabelWithLogo label="Intercom" logo="intercom" />,
  //   limits: [Enabled, Enabled, Enabled, Enabled],
  // },
  {
    name: <TierUsageLabelWithLogo label="Zapier" logo="zapier" />,
    limits: plans.map((p) => (p.features.zapier ? Enabled : null)),
  },
  {
    name: <TierUsageLabelWithLogo label="Segment" logo="segment" />,
    limits: [null, null, Enabled, Enabled],
  },
  {
    name: <TierUsageLabelWithLogo label="Salesforce" logo="salesforce" />,
    limits: [null, null, null, Enabled],
  },
  {
    name: <TierUsageLabelWithLogo label="Hubspot" logo="hubspot" />,
    limits: [null, null, null, Enabled],
  },
  {
    name: (
      <TierUsageLabelWithLogo
        label="Calendar Integration"
        logo={['google', 'office365']}
      />
    ),
    limits: [null, null, null, Enabled],
  },
];

export const CUSTOMER_PORTAL: TierLimitSection = [
  {
    name: 'Roadmap',
    limits: [Enabled, Enabled, Enabled, Enabled],
  },
  {
    name: 'Changelog',
    limits: [Enabled, Enabled, Enabled, Enabled],
  },
  {
    name: 'Custom Domain',
    limits: [Enabled, Enabled, Enabled, Enabled],
  },
  {
    name: 'Whitelabeling',
    limits: [null, null, null, Enabled],
  },
];

export const SERVICES: TierLimitSection = [
  {
    name: 'Shared Slack Channel',
    limits: [null, null, null, Enabled],
  },
  {
    name: <TierUsageLabelWithLogo label="Herald Academy*" logo="herald" />,
    limits: [
      null,
      null,
      <div
        style={{
          borderRadius: 4,
          padding: '.5rem',
          background: colors.GRAY_2(0.5),
          color: colors.GRAY_4(),
        }}
      >
        <strong>Silver</strong>
      </div>,
      <div
        style={{
          borderRadius: 4,
          padding: '.5rem',
          background: colors.YELLOW(0.2),
          color: colors.ORANGE(),
        }}
      >
        <strong>Gold</strong>
      </div>,
    ],
  },
];

export const COMPLIANCE: TierLimitSection = [
  {
    name: 'GDPR & CCPA',
    limits: [Enabled, Enabled, Enabled, 'Signed DPA'],
  },
  {
    name: 'SOC2',
    limits: [null, null, null, 'Available'],
  },
];
