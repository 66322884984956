import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { colors, Plan } from 'herald-fe-shared';
import { graphql } from 'gatsby';

import Layout from '~/layout/LayoutMarketing';
import LargeCTA from '../components/LargeCTA';
import Bicycle from '../illustrations/Bicycle';
import Tier from '~/components/pricing/Tier';
import TierUsageSection from '~/components/pricing/TierUsageSection';
import AllPlans from '~/components/pricing/AllPlans';

import {
  getTiers,
  getProductUsage,
  getIntegrations,
  SERVICES,
  COMPLIANCE,
} from '~/components/pricing/Limits';

const GlobalStyle = createGlobalStyle`
  body {
    background: #F3F3F3;
  }
`;

const NUMBER_OF_TIERS = 4;

const Styled = styled.div`
  padding: 50px;
  margin: auto;
  text-align: center;
  max-width: 80rem;
  display: flex;
  flex-flow: column;

  .text-container {
    max-width: 50rem;
    margin: auto;
    margin-bottom: 2rem;
  }
  .tiers {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(${NUMBER_OF_TIERS + 1}, 1fr);
    background: ${colors.WHITE()};
    box-shadow: 0 0 30px ${colors.BLACK(0.05)};
    border-radius: 5px;
    border: 1px solid ${colors.GRAY_2(0.5)};
  }

  .tiers__divider {
    background: ${colors.GRAY_2(0.6)};
    padding: 0.5rem 2rem;
    border-top: 1px solid ${colors.GRAY_2(0.5)};
    grid-column-start: 1;
    grid-column-end: ${NUMBER_OF_TIERS + 2};
    text-align: left;
  }
  .tiers__divider label {
    font-size: 0.7rem;
  }

  .tier {
    padding: 2rem;
    border-left: 1px solid ${colors.GRAY_2(0.5)};
    text-align: center;
  }
  .tier:first-child {
    border: none;
  }
  .tier--icon {
    overflow: hidden;
    position: relative;
  }
  .tier--icon svg {
    position: absolute;
    left: -25%;
    top: -25%;
    width: 150%;
    height: 150%;
  }
  .tier__name {
    padding: 0.5rem 1rem;
    color: ${colors.GRAY_3()};
    font-size: 0.6rem;
    background: ${colors.GRAY_2(0.5)};
    width: fit-content;
    border-radius: 3px;
    margin: auto;
    margin-bottom: 2rem;
  }
  .tier--Professional .tier__name {
    background: ${colors.PURPLE_DARK(0.05)};
    color: ${colors.PURPLE_DARK()};
  }
  .tier__price {
    margin: 0.5rem 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .tier__price .price--crossed-out {
    position: relative;
    &:before,
    &:after {
      bottom: 50%;
      left: 0;
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background: ${colors.RED()};
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
  .tier__price .price--real {
    margin-left: 1rem;
  }

  .tier__divider {
    width: 3rem;
    border: none;
    border-top: 1px solid ${colors.GRAY_2()};
    margin: 2rem auto;
  }
  .tier__includes {
    display: flex;
    flex-flow: column;
    width: fit-content;
    margin: auto;
  }
  .tier__includes li {
    color: ${colors.GRAY_3()};
    margin-bottom: 0.5rem;
    text-align: left;
  }
  .tier__includes li svg {
    fill: green;
    margin-right: 0.5rem;
  }
  .tier__labels__label,
  .tier__limits__limit {
    border-top: 1px solid ${colors.GRAY_2()};
    padding: 0 2rem;
    text-align: left;
    height: 4rem;
    display: flex;
    align-items: center;
    &:nth-child(odd) {
      background: ${colors.GRAY_1()};
    }
  }
  .tier__labels__label p,
  .tier__limits__limit p {
    font-size: 0.9rem;
    line-height: 1.3rem;
  }
  .tier__labels__label--with-logos {
    display: flex;
    align-items: center;
  }
  .tier__labels__label--with-logos p {
    margin-bottom: 0;
    margin-right: 0.5rem;
    white-space: nowrap;
  }
  .tier__labels__label__logos {
    display: flex;
  }
  .tier__labels__label__logos__logo {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tier__labels__label__logos__logo .gatsby-image-wrapper {
    width: 100%;
  }
  .tier__limits__limit {
    justify-content: center;
  }
  .tier__limits__limit p {
    font-weight: bold;
    text-align: center;
  }
  .tier__limits__limit svg {
    fill: ${colors.GRAY_3()};
    width: 1.25rem;
    height: 1.25rem;
  }
  .tier__limits__limit svg.red {
    fill: ${colors.RED(0.4)};
    width: 1rem;
    height: 1rem;
  }
  .tier__limits {
    border-left: 1px solid ${colors.GRAY_2()};
  }
  .toggle {
    display: none;
    border: 1px solid ${colors.GRAY_2()};
    margin: auto;
    margin-bottom: 2rem;
    width: fit-content;
    border-radius: 5px;
    overflow: hidden;
  }
  .toggle__option {
    padding: 1rem 1.5rem;
    background: white;
    transition: 250ms all;
    cursor: pointer;
    border-right: 1px solid ${colors.GRAY_2()};
  }
  .toggle__option:last-child {
    border: none;
  }
  .toggle__option h5 {
    color: ${colors.GRAY_3()};
  }
  .toggle__option--active {
    background: ${colors.PURPLE_LIGHT(0.1)} !important;
    box-shadow: inset 0 0 10px ${colors.PURPLE_LIGHT(0.3)};
  }
  .toggle__option--active h5 {
    color: ${colors.PURPLE_LIGHT()};
  }
  .toggle__option:hover {
    background: ${colors.GRAY_1()};
  }
  .note {
    width: 100%;
    margin: auto;
    margin-top: 4rem;
  }

  @media screen and (max-width: 1024px) {
    padding: 30px;
  }
  @media screen and (max-width: 800px) {
    padding: 20px;
    .toggle {
      display: flex;
    }
    .tiers {
      grid-template-columns: 2fr 3fr;
    }
    .tier {
      display: none;
    }
    .tier--icon {
      display: block;
    }
    .tier__limits {
      display: none;
    }
    .tier--active,
    .tier__limits--active {
      display: block;
    }
    .tier__labels__label--with-logos {
      flex-flow: row wrap;
    }
    .tier__labels__label__logos--multiple {
      margin-top: 4px;
    }
    .tiers__divider {
      grid-column-end: 3;
    }
  }
  @media screen and (max-width: 600px) {
    margin-top: 10rem;
    .tier__labels__label,
    .tier__limits__limit {
      padding: 0 0.75rem;
    }
    .note {
      margin-top: 2rem;
      order: 2;
    }
  }
`;

interface IPlansQuery {
  allRestApiPrivateMasterBillingCurrentPlans: {
    nodes: Plan[];
  };
}

export interface IAugmentedPlan extends Plan {
  additional?: {
    editors?: number;
    feedback?: number;
  };
}

// const plans: IAugmentedPlan[] = [
//   {
//     id: 'startup',
//     type: 'startup' as any,
//     cents: 1500,
//     limits: { editors: 1, quotes: 100, personas: 0 },
//     additional: { editors: 1500, feedback: 500 },
//   },
//   {
//     id: 'growth',
//     type: 'growth' as any,
//     cents: 15000,
//     limits: { editors: 5, quotes: 1000, personas: 5 },
//     additional: { editors: 3000, feedback: 500 },
//   },
//   {
//     id: 'scale',
//     type: 'scale' as any,
//     cents: 60000,
//     limits: { editors: 10, quotes: 2000, personas: 20 },
//     additional: { editors: 6000, feedback: 500 },
//   },
// ];

const Pricing: React.FC<{ data: IPlansQuery }> = (props) => {
  const plans =
    props.data.allRestApiPrivateMasterBillingCurrentPlans.nodes[0].plans;
  const [active, setActive] = useState('startup');
  const tiers = getTiers(plans);

  return (
    <Layout title="Pricing">
      <Styled>
        <div className="text-container">
          <h1 style={{ marginBottom: 10 }}>Get Started Today</h1>
          <p className="large section-description">
            Sign up for Herald <strong>without a credit card</strong> and keep
            using it <strong>for free</strong>.
          </p>
        </div>
        <div className="toggle">
          {tiers.map((t) => (
            <div
              key={t.id}
              onClick={() => setActive(t.type)}
              className={`toggle__option toggle__option--${
                active === t.type ? 'active' : 'inactive'
              }`}
            >
              <h5>{t.displayName}</h5>
            </div>
          ))}
        </div>
        <AllPlans />
        <div className="tiers">
          <div className="tier tier--icon">
            <Bicycle />
          </div>
          {tiers.map((t) => (
            <Tier
              key={t.id}
              title={t.displayName}
              active={active === t.type}
              price={
                <div style={{ display: 'flex', flexFlow: 'column' }}>
                  {t.price}
                </div>
              }
            />
          ))}
          <TierUsageSection
            active={active}
            limits={getProductUsage(tiers)}
            tiers={tiers}
          />
          <TierUsageSection
            title="Services"
            active={active}
            limits={SERVICES}
            tiers={tiers}
          />
          <TierUsageSection
            title="Integrations"
            active={active}
            limits={getIntegrations(tiers)}
            tiers={tiers}
          />
          {/*<TierUsageSection
            title="Public Customer Portal"
            active={active}
            limits={CUSTOMER_PORTAL}
            tiers={tiers}
          />*/}
          <TierUsageSection
            title="Compliance"
            active={active}
            limits={COMPLIANCE}
            tiers={tiers}
          />
        </div>
        <p className="note">
          * The <strong>Herald Academy</strong> offers on-demand coaching
          sessions for your team. We review your Herald workspace, help organize
          it, and share best practices for your team to orient itself around
          customer needs.{' '}
        </p>
      </Styled>
      <LargeCTA />
      <GlobalStyle />
    </Layout>
  );
};

export const query = graphql`
  query Plans {
    allRestApiPrivateMasterBillingCurrentPlans {
      nodes {
        plans {
          id
          cents
          type
          features {
            zapier
            customDomain
            b2b
            topicSuggestion
          }
          limits {
            editors
            personas
            quotes
          }
          maxEditors
          extraCents {
            editors
            personas
            quotes
          }
        }
      }
    }
  }
`;

export default Pricing;
