import React from 'react';

type Item = string | JSX.Element | null;

interface ITierProps {
  title: Item;
  price: Item;
  active: boolean;
  description?: Item;
  includes?: Item[];
}

const Tier: React.FC<ITierProps> = (props) => {
  const { title, price, description, active } = props;
  return (
    <div
      className={[
        'tier',
        `tier--${title}`,
        `tier--${active ? 'active' : 'inactive'}`,
      ].join(' ')}
    >
      <h5 className="tier__name text-caps">{title}</h5>
      <h3 className="tier__price">{price}</h3>
      <label className="tier__description">{description}</label>
    </div>
  );
};

export default Tier;
