import React from 'react';

interface ITierLimit {
  name: Item;
  limits: [Item, Item, Item, Item];
}

type Item = string | JSX.Element | null;

const TierUsageLabel: React.FC<any> = (props) => {
  const { label } = props;
  return (
    <div className="tier__labels__label">
      {typeof label === 'string' ? (
        <p className="text-no-margin">{label}</p>
      ) : (
        label
      )}
    </div>
  );
};

const TierUsageLimit: React.FC<any> = (props) => {
  const { label } = props;
  return (
    <div className="tier__limits__limit">
      {typeof label === 'string' ? (
        <p className="text-no-margin">{label}</p>
      ) : (
        label
      )}
    </div>
  );
};

const TierUsageSection: React.FC<any> = (props) => {
  const { limits, active, title, tiers } = props;
  return (
    <React.Fragment>
      {title && (
        <div className="tiers__divider">
          <label className="text-gray">{title}</label>
        </div>
      )}
      <div className="tier__labels">
        {limits.map((l: ITierLimit, i) => (
          <TierUsageLabel key={i} label={l.name} />
        ))}
      </div>
      {tiers.map((t, i) => (
        <div
          key={t.id}
          className={`tier__limits tier__limits--${
            active === t.type ? 'active' : 'inactive'
          }`}
        >
          {limits.map((l, limitIndex) => (
            <TierUsageLimit key={limitIndex} label={l.limits[i]} />
          ))}
        </div>
      ))}
    </React.Fragment>
  );
};

export default TierUsageSection;
